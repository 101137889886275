@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
        @apply px-2 py-1 2xl:px-4 2xl:py-2 max-h-10 2xl:h-auto text-white text-sm font-semibold uppercase border-2 border-blue-light rounded-md bg-blue-light hover:bg-transparent hover:text-blue-text;
    }

    .btn-outline {
        @apply bg-transparent text-blue-text hover:bg-blue-light hover:text-white;
    }

    .btn-dark-bg {
        @apply bg-transparent text-white border-white hover:bg-white hover:text-blue-light;
    }

    .btn-disabled {
        @apply bg-blue-light-75 hover:bg-blue-light-75 border-blue-light-50 text-blue-50 hover:text-blue-50 cursor-default;
    }

    .input {
        @apply border-2 border-gray-light rounded-lg w-full px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-light focus:border-transparent;
    }

    .chip {
        @apply py-1 px-2 border-2 rounded-lg font-semibold uppercase cursor-pointer;
    }

    .chip-green {
        @apply chip border-green-600 text-green-600 hover:text-green-700 hover:border-green-700 hover:bg-green-100;
    }

    .chip-yellow {
        @apply chip border-yellow-600 text-yellow-600 hover:text-yellow-700 hover:border-yellow-700 hover:bg-yellow-100;
    }

    .chip-red {
        @apply chip border-red-600 text-red-600 hover:text-red-700 hover:border-red-700 hover:bg-red-100;
    }
}

/* width */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.modal ::-webkit-scrollbar-track {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.modal .MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiInput-input ::-webkit-scrollbar-track {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

.modal ::-webkit-scrollbar-thumb {
    border-top-right-radius: calc(1rem - 2px);
    border-bottom-right-radius: calc(1rem - 2px);
}

.modal .MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiInput-input ::-webkit-scrollbar-thumb {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


