@import "../variables";
@import "../mixins";

.capse-container {
  margin: 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;

  .capse-header {
    background-color: $blue;
    padding: 4px;
    color: white;
    border-bottom: 1px solid lightgray;
  }

}

.capse-table-container {
  @include border-container;
  padding: 0;

  .capse-table-inner {
    margin: 12px;
    border: 1px solid lightgray;
    border-radius: 4px;
    background-color: white;
  }

}

.capse-m-h {
  height: 60px;
  overflow-y: auto;
}
