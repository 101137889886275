@import "../variables";

.capse-banner {
  background-color: $body-bg;
  display: flex;
  align-items: center;

  .img-container {
    padding: 24px;
    background-color: $blue;
    border-radius: 50%;
    position: relative;
    left: 20px;

    img {
      width: 75px;
      height: auto;
    }
  }

  h3 {
    padding: 24px;
    background-color: $blue;
    width: 100%;
  }
}




